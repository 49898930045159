export default [
    {
        title: '序号',
        dataIndex: 'num',
        key: 'num',
        customRender:(t,r,index)=>{
            return index + 1
        }

    },
    // {
    //     dataIndex: 'id',
    //     key: 'id',
    //     slots: { title: 'id' },
    //     scopedSlots: { customRender: 'id' },
    // },
    {
        title: '名称',
        key: 'regName',
        dataIndex: 'regName',
        slots: { title: '名称' },
    },
    {
        title: '校验码',
        key: 'regCode',
        dataIndex: 'regCode',
        slots: { title: '校验码' },
    },
    {
        title: '有效',
        key: 'isEnabled',
        dataIndex: 'isEnabled',
    },
    {
        title: '创建时间',
        align: 'center',
        dataIndex: 'createTime',
    },
    {
        title: 'Action',
        key: 'action',
        scopedSlots: { customRender: 'action' },
    },
];