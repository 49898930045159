
<template>
  <div>
<!--    <div slot="extra">-->
<!--    </div>-->
    <a-card class="mb-16">
      <div class="flex">
        <div class="flex flex-wrap flex-align-center flex-child-grow">
          <!-- <div class="flex flex-align-center search-item"> -->
            <span class="flex-child-noshrink">名称：</span>
            <a-input placeholder="名称" v-model="screening.regName" class="mr-20" style="width: 160px;margin:5px;" allowClear/>
          <!-- </div> -->
          <!-- <div class="flex flex-align-center search-item"> -->
            <span class="flex-child-noshrink">校验码：</span>
            <a-input placeholder="校验码：" v-model="screening.regCode" class="mr-20" style="width: 160px;margin:5px;" allowClear/>
          <!-- </div> -->
          <a-button icon="search" type="primary" @click="doScreening" style="margin-right:5px;">筛选</a-button>
          <a-button type="primary" @click="onAdd()">新增</a-button>
        </div>
      </div>
    </a-card>
    <a-card class="table-box">
      <a-table :columns="columns" :data-source="list" rowKey="id"
               :pagination="pagination"
               :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
               @change="handleTableChange">
        <!-- <div slot="num">
            {{text}} aa {{this.pagination.current}}
        </div>
        <div slot="regName" slot-scope="text">
          <span>{{text}}</span>
        </div>
        <div slot="regCode" slot-scope="text">
          <span>{{text}}</span>
        </div>
        <div slot="isEnabled" slot-scope="text">
          <span>{{text}}</span>
        </div>
        <div slot="createTime" slot-scope="text">
          <span>{{text}}</span>
        </div> -->
        <div class="flex flex-row flex-center" slot="action" slot-scope="text, record">
          <a-button  type="primary" size="small" class="margin-left" @click="onAdd(record)">编辑</a-button>
          <a-button  type="primary" size="small" class="margin-left" @click="onDel(record)">删除</a-button>
        </div>
      </a-table>
    </a-card>
    <a-modal :title="editTitle" v-model="modalShow" @ok="handleSubmit" :maskClosable="false" @cancel="handleCancle" :width="800">
      <a-form  :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="名称" required>
          <a-input v-model="form.regName"></a-input>
        </a-form-item>
        <a-form-item label="校验码" required>
          <a-input v-model="form.regCode"></a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
  import columns from './columns'
  import { mapState } from 'vuex'

  export default {
    // components: { staffInput },
    data () {
      return {
        editTitle: '',
        title: '',
        apiUrl: this.$api.manage, // 依据实际前端接口目录修改接口地址
        screening: {},
        pagination: {
          current: 1,
          pageSize: 20,
          pageSizeOptions: ['10', '20', '50', '100'],
          showSizeChanger: true
        },
        columns: columns,
        selectedRowKeys: [],
        list: [],
        modalShow: false,
        form: {
          id: null,
          regName: '名称',
          regCode: '校验码'
        }
      }
    },
    created () {
      // console.log("list 111start")
      // console.log(this.$store)
      console.log(this.$store.state.roles)
      this.title = '校验码列表'
      this.initFetchData()
      // if (this.$tnt.id !== 0) { // 移除租户和操作人栏
      //   let tenantIndex = this.columns.findIndex(item => item.dataIndex === 'xtenantName')
      //   tenantIndex > -1 && this.columns.splice(tenantIndex, 1)
      //   let index = this.columns.findIndex(item => item.dataIndex === 'ch999Name')
      //   index > -1 && this.columns.splice(index, 1)
      // }
    },
    computed: {
      ...mapState({
        userInfo: state => {
          return state.userInfo || {}
        }
      })
    },
    methods: {
      onSelectChange(selectedRowKeys) {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.selectedRowKeys = selectedRowKeys;
      },
      changeCreateUser (value) {
        console.log('changeCreateUser', value)
        this.screening.createUserId = value ? value.id : ''
      },
      changech999User (value) {
        console.log('changech999User', value)
        this.screening.ch999Id = value ? value.id : ''
      },
      // 筛选条件分页数据合并
      queryParams () {
        // if (!this.isAll) {
        //   let id = this.userInfo.UserID
        //   this.$tnt.id === 0 ? this.screening.ch999Id = id : this.screening.createUserId = id
        // }
        return {
          current: this.pagination.current,
          size: this.pagination.pageSize,
          ...this.screening
        }
      },
      // fetchDetail (id) {
      //   this.$indicator.open()
      //   this.$api.bussiness.fetchDetail(id).then(res => {
      //     if (res.code === 0) {
      //       this.form = res.data
      //       this.modalShow = true
      //     } else {
      //       this.$message.error(res.message)
      //     }
      //   }).finally(() => {
      //     this.$indicator.close()
      //   })
      // },
      // 新增
      onAdd (record) {
        if (record) {
          this.editTitle = '编辑校验码'
          this.form = record
        } else {
          this.editTitle = '新增校验码'
          this.form = {
            id: null,
            regName: '名称',
            regCode: '校验码'
          }
        }
        this.modalShow = true
      },
      // 列表项删除
      onDel (el) {
        console.log('列表项信息删除', el)
        this.$confirm({
          title: '你确定要删除此校验码吗?',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk: () => {
            // TODO: 敏感操作二次确认，在此写对应的执行操作 @Case 2020-10-10 17:12:57
            this.deleteFetch(el.id)
          }
        })
      },
      // modal框确定操作
      handleSubmit () {
        let model = {
          regName: '请填写名称',
          regCode: '请填写校验码'
        }
        for (let key in model) {
          if (this.form[key] === '' || this.form[key] === undefined) {
            this.$message.warn(model[key])
            return
          }
        }
        this.$indicator.open()
        this.apiUrl.updateRegCode({
          ...this.form
        }).then(res => {
          if (res.code === 200) {
            this.modalShow = false
            this.initFetchData()
            this.$message.success(res.message || '操作成功')
          } else {
            this.$message.error(res.message)
          }
        }).finally(() => {
          this.$indicator.close()
        })
      },
      // modal框取消/关闭/遮罩关闭操作
      handleCancle () {
        this.form = {}
      },
      // 筛选搜索
      doScreening () {
        // console.log('筛选啦', this.screening)
        // if (Object.keys(this.screening).length === 0) {
        //   this.$message.error('你都没选，筛个毛啊你筛，好好检查!!!')
        //   return
        // }
        this.pagination.current = 1
        this.initFetchData()
      },
      // 批量操作
      onBatchHandle () {
      },
      // 切换分页大小
      handleTableChange (pagination) {
        this.pagination = pagination
        this.initFetchData()
      },
      // 页面初始化数据API请求
      initFetchData () {
        let params = this.queryParams()
        this.$indicator.open()
        this.apiUrl.fetchList(params)
          .then(res => {
            console.log(res)
            if (res.code === 200) {
              this.$set(this.pagination, 'total', res.data.total)
              this.list = res.data.records
            } else {
              this.$message.error(res.message)
            }
          }).finally(() => {
            this.$indicator.close()
          })
      },
      // 获取数据
      getDataFetch (params) {
        this.$indicator.open()
        this.apiUrl.get(params)
          .then(res => {
            if (res.code === 0) {
              console.log('数据', res.data) // 只用于调试方便看数据，正式环境请注释或删除
              // FIXME: 根据数据结构实际情况做调整 @Case 2020-10-10 17:10:17
              this.list = res.data
            } else {
              this.$message
                .error(res.message)
            }
          }).finally(() => {
            this.$indicator.close()
          })
      },
      // 数据删除
      deleteFetch (params) {
        this.$indicator.open()
        this.apiUrl.delRegCode(params)
          .then(res => {
            if (res.code === 200) {
              this.$message.success('删除成功')
              this.initFetchData()
            } else {
              this.$message
                .error(res.message)
            }
          }).finally(() => {
            this.$indicator.close()
          })
      }
    }
  }
</script>

<style lang="scss" scoped>

  .table-box {
    /*批量操作固定左下角样式*/
    position: relative;

    .footer-btn {
      position: absolute;
      bottom: 18px;
      left: 12px;
    }
  }
  .search-item {
    margin: 10px 0;
  }
</style>
